<template>
  <div class="index-page">
    <Swiper :configInfo="configInfo" />

    <HotSearch :configInfo="configInfo" />

    <div class="divider">
      <marquee>
        {{ L(configInfo.TOP_NOTICE) }}
      </marquee>
    </div>
    <Job />

    <a href="https://dg.upapi.cn/portal/#/news/detail?code=ACTIVITY" target="_blank">
      <img class="banner" src="https://s.upapi.cn/2022/05/13/34ad9a28016c757d527380151dc53480/banner.fd196409.jpg"
        alt="" />
    </a>

    <div class="container">
      <Company />
      <News />
    </div>

    <major-selector :configInfo="configInfo" />
  </div>
</template>

<script>
import Swiper from "./components/swiper";
import HotSearch from "./components/hot-search";
import Job from "./components/job";
import Company from "./components/company";
import News from "./components/news";
import MajorSelector from "./components/major-selector";
import { fetchConfig } from "@/api/config";
import { mapMutations, mapState } from "vuex";

export default {
  components: {
    Swiper,
    HotSearch,
    Job,
    Company,
    News,
    MajorSelector,
  },

  data() {
    return {
      configInfo: {}, // 配置信息
    };
  },

  computed: {
    ...mapState("major", ["selected"]),
  },

  mounted() {
    const { from } = this.$route.query;
    if (from) {
      window.localStorage.setItem("activity_from", from);
    }

    fetchConfig().then((res) => {
      this.configInfo = res;
      window.localStorage.setItem("banner_image", res.SITE_BANNER);

      if (this.selected.length === 0) {
        this.setVisible(true);
      }
    });
  },

  methods: {
    ...mapMutations("major", ["setVisible"]),
  },
};
</script>

<style lang="less" scoped>
.index-page .divider {
  max-width: 1400px;
  margin: 0 auto;
  border-bottom: 1px dashed #979797;
}

.index-page .banner {
  display: block;
  width: 100%;
  cursor: pointer;
}

.index-page .entrance {
  padding: 48px;
  margin-bottom: 70px;
  background: #f1f1f1;

  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.index-page .entrance .entrance-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.index-page .entrance .entrance-title {
  font-size: 30px;
  color: #000000;
  margin-bottom: 50px;
}

.index-page .entrance button {
  width: 130px;
  height: 40px;
  border: 1px solid #20317f;
  background-color: #fff;
  font-size: 14px;
  color: #20317f;
  cursor: pointer;
}

.index-page .entrance button:hover {
  background-color: #20317f;
  color: #fff;
}

.index-page .entrance .separator {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.8);
  position: relative;
}

.index-page .entrance .separator::before {
  content: "";
  position: absolute;
  top: -58px;
  height: 53px;
  left: 49%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

.index-page .entrance .separator::after {
  content: "";
  position: absolute;
  bottom: -58px;
  height: 53px;
  left: 49%;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}

marquee {
  max-width: 1400px;
  margin: 0 auto;
  margin-bottom: 10px;
}
</style>